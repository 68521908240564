import React from 'react';
import { Container } from "react-bootstrap";
import styles from "./styles.module.scss";

const NFTContainer = props => {
    return (
        <Container
            className={`
                ${styles.nft_container} 
                ${props.className} 
                ${props.varient ? styles[`container_${props.varient}`] : ""}
            `} style={props.style}>
            {props.children}
        </Container>
    )
}


export default NFTContainer;
