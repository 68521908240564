import Image from "next/image";
import React from "react";
import styles from "./styles.module.scss";
import { useMediaQuery } from "../../../Hooks/useMediaQuery";

const EditProfileGemsBox = () => { 
  const mobileScreen = useMediaQuery('(max-width:1193px)');

  return (
    <div className={`${styles.profilegemsbox}`}>
      <div className={`${styles.gems_img}`}>
        <Image src="/assets/gemstarlogo.svg" alt="Logo" layout='fill' />
      </div>

      <div className={`${styles.profilegems_details}`}>
        <div className={`${styles.profilegems_title_separates}`}>
          <h2 className={`${styles.profilegemstitles}`}>
            Earn Gems - 
            <span className={`${styles.profilegemsvalues}`}>100</span>
          </h2>
          <div className={`${styles.gems_small_img}`}>
            <Image src="/assets/gemssimple.svg" alt="Logo" layout='fill' />
          </div>
        </div>
        <p className={`${styles.profilegemsdetails}`}>
          After Profile Completion
        </p>
        <span className={`${styles.quickFast}`}>Quick Fast</span>
        <p className={`${styles.profilegemstags}`}>
          {/* Earn 100 gems by completing your profile with some basic details like your profile picture, name, social media links, and a little bit about yourself. */}
        </p>
      </div>
    </div>
  );
};

export default EditProfileGemsBox;
